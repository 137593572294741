.root {
	margin-bottom: 10px;

	.label {
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: #738094;

		.fieldRequired {
			color: red;
		}

		div {
			display: flex;
			align-items: center;
			margin-left: 5px;
		}
	}
}
