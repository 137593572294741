@font-face {
	font-display: swap;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 300;
	src: url('./fonts/Inter-Light.ttf') format('truetype'), url('./fonts/Inter-Light.woff') format('woff'),
		url('./fonts/Inter-Light.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/Inter-Regular.ttf') format('truetype'), url('./fonts/Inter-Regular.woff') format('woff'),
		url('./fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	src: url('./fonts/Inter-Medium.ttf') format('truetype'), url('./fonts/Inter-Medium.woff') format('woff'),
		url('./fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	src: url('./fonts/Inter-SemiBold.ttf') format('truetype'), url('./fonts/Inter-SemiBold.woff') format('woff'),
		url('./fonts/Inter-SemiBold.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	src: url('./fonts/Inter-Bold.ttf') format('truetype'), url('./fonts/Inter-Bold.woff') format('woff'),
		url('./fonts/Inter-Bold.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 800;
	src: url('./fonts/Inter-ExtraBold.ttf') format('truetype'), url('./fonts/Inter-ExtraBold.woff') format('woff'),
		url('./fonts/Inter-ExtraBold.woff2') format('woff2');
}

body {
	background-color: #eef2f6;
	margin: 0 !important;
	padding: 0 !important;
	font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

img {
	width: 100%;
	height: auto;
}

.MuiButton-root {
	box-shadow: none !important;
}

/* Ant Popover */
.ant-popover-inner {
	border-radius: 5px;
	padding: 6px 8px;
	max-width: 240px;
}

.ant-popover-title {
	border-bottom: none;
	padding: 0;
	margin: 0;
	min-height: auto;
	color: #2f3747;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
}

.ant-popover-inner-content {
	padding: 0;
	color: #738094;
	font-family: Inter;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
}
/* Ant Popover End */
