.wrapper {
	display: flex;
	align-items: center;
	& div {
		width: auto;
		max-width: 100%;
	}
}

.title {
	margin-right: 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.editBlock {
	width: 100%;
}
