.accordion {
	border: none;
	margin-bottom: 15px;
	border-radius: 10px;
	background: #f8fafc;

	.accordionHeader {
		display: flex;
		align-items: center;
		padding: 15px;
		cursor: pointer;

		.accordionToggleIcon {
			font-size: 18px;
			margin-right: 10px;
			transition: transform 0.3s ease;

			&.rotate {
				transform: rotate(180deg);
			}
		}

		.accordionTitle {
			margin: 0 15px;
			color: #722ed1;
			font-family: Inter;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px;
			width: 445px;
		}

		.accordionRightContent {
			display: flex;

			.accordionRightLine1 {
				color: #738094;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;
			}

			.accordionRightLine2 {
				margin-left: 16px;
				color: #722ed1;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px;
			}

			.negative {
				color: #f5222d;
			}
		}
	}

	.accordionContent {
		padding: 0 15px;
	}
}
