.root {
	padding-left: 6%;
	padding-right: 24px;
	margin-bottom: 8px;
}

.moreButton {
	text-transform: lowercase !important;
}

.withAllComments {
	padding: 0;
	grid-area: 4 / 1 / 4 / 2;
}

.withAllCommentsItem {
	border-radius: 10px;
	background-color: #fff;
}

.title {
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
	padding-top: 16px;
	margin-bottom: 24px;
}
