.root {
	display: flex;
	align-items: center;

	.text {
		margin-right: 5px;

		&.gray {
			color: #738094;
		}

		&.black {
			color: #2f3747;
		}

		&.lg {
			font-size: 18px;
			line-height: 24px;
			font-weight: 700;
		}

		&.md {
			font-size: 14px;
			line-height: 24px;
			font-weight: 600;
		}

		&.mdL {
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}

		&.mds {
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
		}

		&.xs {
			font-size: 12px;
			line-height: 16px;
			font-weight: 400;
		}

		&.overall {
			font-weight: 400;
		}

		font-weight: 400;
	}

	.questionWrapper {
		display: flex;
		align-items: center;
		cursor: pointer;

		.titleAfterQuestion {
			color: #a3aebe;
			margin-left: 8px;
		}
	}

	span {
		color: red;
	}

	div {
		display: flex;
		align-items: center;
		margin-left: 5px;
	}
}

.required {
	color: red;
}
