.root {
	border-radius: 10px;
	background: #eef2f6;
	padding: 16px;
	margin-bottom: 25px;

	.title {
		color: #2f3747;
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
		margin-bottom: 15px;
	}

	.selectBlockHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 15px 0 10px 0;

		.leftSelectTitle {
			width: 50%;
		}

		.rightSelectTitle {
			width: 50%;
		}
	}

	.selectBlock {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		.leftSelect {
			width: 42%;
		}

		.rightSelect {
			width: 42%;

			.textarea {
				height: auto;
			}
		}

		.deletBtn {
			width: 5%;
		}
	}

	.failedToRetrieveData {
		text-align: center;
		color: #ff0000;
	}
}
