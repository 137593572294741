@import 'styles/variables';

.body {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.textarea {
	height: 64px;
	border-radius: 5px !important;
	border: 1px solid $gray_5 !important;
	background-color: $gray_2 !important;
	color: $gray_7 !important;
	vertical-align: top !important;
}

.textareaWrapper {
	flex-grow: 1;
	margin-right: 8px;
}

.titleWrapper {
	width: 350px;
	margin-bottom: 10px;
}

.title {
	border: 1px solid $gray_5 !important;
	color: $gray_7 !important;
}

.textarea::placeholder {
	color: #afb7c3 !important;
}

.textareaTitle {
	margin-bottom: 4px;
}

.textareaRoot {
	margin-bottom: 10px;
}

.checkbox {
	margin-right: 8px;

	& span {
		margin-right: 4px;
		color: $gray_9;
	}
}

.addButton {
	color: $gray_10 !important;
}

.header {
	display: flex;
	position: relative;
	margin-bottom: 8px;
}

.closeButton {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	padding: 0;
	width: 15px;
	height: 15px;

	& button {
		min-width: 15px !important;
		min-height: 15px !important;
	}
}

.addTitleButton {
	background-color: transparent;
	padding: 0;

	&:hover {
		color: $purple_6;
	}
}
