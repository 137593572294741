.root {
	width: auto;
	margin-right: 8px !important;

	&.outlined {
		text-transform: none;
		border-radius: 5px;
		border: 1px solid #9254de;
	}
	&.filled {
		text-transform: none;
		border-radius: 5px;
		background-color: #9254de;
		color: white;

		&:hover {
			background-color: rgb(102, 58, 155);
		}
	}
	&.plain {
		text-transform: none;
		border: none;
		background: none;
		text-wrap: nowrap;

		&:hover {
			border: none;
			background: none;
		}
	}
	&.ghost {
		text-transform: none;
		border-radius: 5px;
		background-color: #efdbff;
		color: #22075e;
		padding: 6px 16px;

		&:hover {
			background: darken(#efdbff, 5%) !important;
		}
	}
	&.disabled {
		background-color: #e3e8ef;
		pointer-events: none;
	}
}
