.form {
	position: relative;
	height: 100%;
}

.footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: 24px;
}
