.enhancedSelect {
	position: relative;
	width: 300px;
	margin-right: 8px;

	.selectInput {
		margin-top: 1px;
		position: relative;

		input {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 100%;
			height: 35px;
			padding: 8px;
			border: 1px solid #e3e8ef;
			border-radius: 4px;
			outline: none;
			color: #a3aebe;
			background-color: #f8fafc;
		}

		.selectedOptionsContainer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: auto;
			padding: 4px 8px;
			border-radius: 4px;
			outline: none;
			color: #a3aebe;
			background-color: #f8fafc;
			border: 1px solid #e3e8ef;

			.left {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 5px;

				.selectedOption {
					display: flex;
					align-items: center;
					justify-content: space-between;
					max-width: 200px;
					min-width: 50px;
					padding: 2px 5px;
					margin-right: 4px;
					background-color: #efdbff !important;
					border-radius: 5px !important;
					box-sizing: border-box;
					height: 24px !important;
					font-family: Inter, sans-serif !important;
					font-size: 12px;
					color: #531dab !important;
					border: 1px solid #b37feb !important;

					.label {
						max-width: 130px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						word-wrap: break-word;
						word-break: break-all;
					}

					.closeIconWrapper {
						display: flex;
						background: transparent;
						cursor: pointer;
						padding: 0;
						margin-left: 7px;

						.closeIcon {
							fill: #531dab;
							height: 15px;
							width: 16px;
						}
					}
				}

				input {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					width: 100%;
					height: 35px;
					border: none;
					border-radius: 4px;
					outline: none;
					color: #a3aebe;
					background-color: #f8fafc;
				}
			}

			.closeIconWrapper {
				display: flex;
				background: transparent;
				cursor: pointer;
				padding: 0;
				margin-left: 7px;

				.closeIcon {
					fill: hsl(0, 0%, 80%);
					height: 16px;
					width: 18px;

					&:hover {
						fill: hsl(0, 0%, 60%);
					}
				}
			}
		}
	}

	.selectOptions {
		display: inline-block;
		position: absolute;
		top: 100%;
		left: 0;
		width: 350px;
		min-width: 70px;
		min-height: 40px;
		max-height: 400px;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 1000;
		transition: all 100ms;
		background-color: #f8fafc;
		border-color: hsl(0, 0%, 80%);
		border-radius: 5px;
		border-style: solid;
		border-width: 1px;
		box-shadow: none;
		box-sizing: border-box;
		border: 1px solid #e3e8ef;
		padding: 0;
		padding-bottom: 10px;

		/* Стилизация скроллбара */
		&::-webkit-scrollbar {
			width: 5px;
			background: transparent; /* Без фона */
		}

		&::-webkit-scrollbar-thumb {
			background-color: #a3aebe; /* Цвет ползунка */
			border-radius: 10px; /* Закругленные края */
		}

		&::-webkit-scrollbar-thumb:hover {
			background-color: #718096; /* Цвет ползунка при наведении */
		}

		&::-webkit-scrollbar-track {
			background: transparent; /* Без фона для трека */
		}

		.selectOption {
			display: flex;
			align-items: center;
			padding: 8px 25px 0 25px;
			list-style: none;

			.label {
				display: flex;
				justify-content: space-between;
				width: 100%;
				cursor: pointer;

				.labelContainer {
					flex-grow: 1;
					text-align: left;
					word-wrap: break-word;
					word-break: break-all;
				}

				input {
					margin-left: 8px;
					margin-right: 0;
				}
			}

			&:hover {
				width: 100%;
				background-color: #eef2f6;
				color: #722ed1;
			}
		}

		.noOptions {
			padding: 8px 25px 0 25px;
			text-align: center;
			color: #a3aebe;
		}
	}
}
