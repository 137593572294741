@import '../../../../../../styles/variables.scss';

$min_width: 44px;

.tagItem {
	margin-right: 4px;
	margin-bottom: 4px;
	& .loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 22px;
	}
}

.tooltipMenu {
	padding: 5px 12px;
	line-height: 22px;
}

.tooltipMenuWithLine {
	padding: 5px 12px;
	line-height: 22px;
	border-top: 1px solid #e3e8ef;
}

.tooltipMenuOne {
	padding: 0px 12px;
	line-height: 22px;
}

.subTitle {
	color: $gray_7;
	padding-bottom: 4px;
	font-size: 12px;
	text-wrap: balance;
}

.title {
	text-wrap: balance;
}

.settings {
	position: absolute;
	top: 0px;
	right: 0px;
	height: 100%;
	width: 22px;
	cursor: pointer;
}

.settingsForTablet {
	position: absolute;
	top: 1px;
	right: 1px;
	height: 100%;
	width: 22px;
	cursor: pointer;
	text-align: left;
}

.loading {
	width: auto;
	height: 100%;
}

.tagGhost {
	position: relative;
	display: inline-block;
	padding: 1px 8px;
	border-radius: 5px;
	color: #738094;
	border: 1px dashed #cdd5df;
	cursor: pointer;
	font-size: 12px;
	line-height: 18px;
}

.tagGhostWithTitle {
	position: relative;
	display: inline-block;
	padding: 1px 8px;
	border-radius: 5px;
	color: $green_7;
	border: 1px dashed $green_4;
	cursor: pointer;
	background-color: $green_1;
	font-size: 12px;
	line-height: 18px;
}

.tagFull {
	position: relative;
	display: inline-block;
	padding: 1px 8px;
	border-radius: 5px;
	color: $green_9;
	border: 1px solid $green_4;
	cursor: pointer;
	font-size: 12px;
	line-height: 18px;
	background-color: $green_2;
	min-width: $min_width;
}
.tagHover {
	position: relative;
	width: 100%;
}
