.root {
	width: auto;
	margin-right: 8px !important;

	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	&:hover {
		background: #eef2f6;
	}

	&.outlined {
		border-radius: 5px;
		border: 1px solid #cdd5df;
		background: #f8fafc;
		color: #2f3747;
		text-transform: none;
	}

	&.active,
	&.loading {
		border: 1px solid #9254de;
		background: #efdbff;
		color: #22075e;
		&:hover {
			background: #efdbff;
		}
	}

	&.disabled {
		background: #f8fafc;
		border: 1px solid #f8fafc;
		color: #738094;
		pointer-events: none;
	}
}
