@import 'styles/variables';

.metaInfo {
	margin-top: 15px;

	.skeleton {
		margin-bottom: 15px;
	}

	.item {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.title {
		font-weight: 600;
		color: $gray_7;
	}

	.value {
		margin-left: 5px;
	}

	.link {
		color: $blue_5;
	}
}
