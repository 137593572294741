.topFlex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	position: relative;

	.topTitle {
		display: flex;
		align-items: center;
		width: 100%;
		font-weight: 600;
		font-size: 14px;
		line-height: 22px;
	}

	.topTitleRight {
		width: 150px;
		position: absolute;
		left: 53.8%;
	}
}
