@import '../../../styles/variables';

$maxWidth: 150px;
$min_width: 44px;

// тег в виде таблетки (тег + значение)
.tagTablet {
	display: flex;
	font-size: 12px;
	line-height: 20px;
	margin: 0 4px 4px 0;
	border-radius: 5px;
	text-wrap: nowrap;
	user-select: auto;

	& span {
		display: flex;
		height: 100%;
		padding: 1px 8px;
	}

	& .name {
		border-radius: 5px 0px 0px 5px;
	}
	& .value {
		border-radius: 0px 5px 5px 0px;
	}
}

// тег в виде стопочки (в теле коммуникации)
.tagTabletStack {
	display: grid;
	flex-wrap: wrap;
	font-size: 12px;
	line-height: 18px;
	margin: 0 4px 4px 0;
	border-radius: 5px;
	text-wrap: nowrap;
	text-align: center;
	user-select: auto;
	& span {
		padding: 1px 8px;
		display: block;
	}

	& .name {
		border-radius: 5px 5px 0px 0px;
		border-bottom: none !important;
	}
	& .value {
		border-radius: 0px 0px 5px 5px;
	}
}

// тег обычный (без значения)
.tagSimple {
	display: flex;
	font-size: 12px;
	line-height: 20px;
	margin: 0 4px 4px 0;
	border-radius: 5px;
	text-wrap: nowrap;
	user-select: auto;
	& span {
		padding: 1px 8px;
		border-radius: 5px;
	}
}

.tagMinWidth {
	min-width: $min_width;
}

.tagFlex {
	display: flex !important;
}

.ellipsis {
	max-width: $maxWidth;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
	display: block;
}

// темы тегов
.blueTag {
	& .tagTablet,
	& .tagTabletStack {
		color: $geekblue_9;
		& .name {
			border: 1px solid $geekblue_5;
			background-color: $geekblue_2;
			border-right: 1px solid $geekblue_5;
		}
		& .value {
			border: 1px solid $geekblue_5;
			border-left: none;
			background-color: $geekblue_3;
		}
	}

	& .tagTabletStack {
		& .value {
			border-left: 1px solid $geekblue_5;
		}
	}

	& .tagSimple {
		border: 1px solid $geekblue_5;
		color: $geekblue_9;
		& span {
			background-color: $geekblue_2;
		}
	}
}

.grayTag {
	& .tagTablet,
	& .tagTabletStack {
		color: $gray_9;
		& .name {
			border: 1px solid $gray_5;
			background-color: $gray_2;
			border-right: 1px solid $gray_5;
		}
		& .value {
			border: 1px solid $gray_5;
			border-left: none;
			background-color: $gray_3;
		}
	}

	& .tagTabletStack {
		& .value {
			border-left: 1px solid $gray_5;
		}
	}

	& .tagSimple {
		border: 1px solid $gray_5;
		color: $gray_9;
		& span {
			background-color: $gray_2;
		}
	}
}

.purpleTag {
	& .tagTablet,
	& .tagTabletStack {
		color: $purple_9;
		& .name {
			border: 1px solid $purple_5;
			background-color: $purple_2;
			border-right: 1px solid $purple_5;
		}
		& .value {
			border: 1px solid $purple_5;
			border-left: none;
			background-color: $purple_3;
		}
	}

	& .tagTabletStack {
		& .value {
			border-left: 1px solid $purple_5;
		}
	}

	& .tagSimple {
		border: 1px solid $purple_5;
		color: $purple_9;
		& span {
			background-color: $purple_2;
		}
	}
}

.orangeTag {
	& .tagTablet,
	& .tagTabletStack {
		color: $orange_9;
		& .name {
			border: 1px solid $gold_5;
			background-color: $orange_2;
			border-right: 1px solid $gold_5;
		}
		& .value {
			border: 1px solid $gold_5;
			border-left: none;
			background-color: $orange_3;
		}
	}

	& .tagTabletStack {
		& .value {
			border-left: 1px solid $gold_5;
		}
	}

	& .tagSimple {
		border: 1px solid $gold_5;
		color: $orange_9;
		& span {
			background-color: $orange_2;
		}
	}
}

.lightBlueTag {
	& .tagTablet,
	& .tagTabletStack {
		color: $cyan_9;
		& .name {
			border: 1px solid $cyan_5;
			background-color: $cyan_2;
			border-right: 1px solid $cyan_5;
		}
		& .value {
			border: 1px solid $cyan_5;
			border-left: none;
			background-color: $cyan_3;
		}
	}

	& .tagTabletStack {
		& .value {
			border-left: 1px solid $cyan_5;
		}
	}

	& .tagSimple {
		border: 1px solid $cyan_5;
		color: $cyan_9;
		& span {
			background-color: $cyan_2;
		}
	}
}

.greenTag {
	& .tagTablet,
	& .tagTabletStack {
		color: $green_9;
		& .name {
			border: 1px solid $green_4;
			background-color: $green_2;
			border-right: 1px solid $green_4;
		}
		& .value {
			border: 1px solid $green_4;
			border-left: none;
			background-color: $green_3;
		}
	}

	& .tagTabletStack {
		& .value {
			border-left: 1px solid $green_4;
		}
	}

	& .tagSimple {
		border: 1px solid $green_4;
		color: $green_9;
		& span {
			background-color: $green_2;
		}
	}
}

// наведения на теги
.gray_bgTag span {
	background-color: $gray_3 !important;
}
.blue_bgTag span {
	background-color: $geekblue_3 !important;
}
.purple_bgTag span {
	background-color: $purple_3 !important;
}
.orange_bgTag span {
	background-color: $orange_3 !important;
}
.lightBlue_bgTag span {
	background-color: $cyan_3 !important;
}
.green_bgTag span {
	background-color: $green_3 !important;
}

// модальное окно
.modalBtns {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
}

.count {
	padding-left: 2px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
}

.titleModal {
	color: $gray_9 !important;
	font-size: 16px !important;
	font-weight: 600 !important;
}

.contentModal {
	margin-top: 24px;
}
