.root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 48px;
	border-radius: 10px;
	border: 1px solid #e3e8ef;
	margin-right: 10px;
	cursor: pointer;

	&:hover {
		background: #f9f0ff;
	}

	.icon {
		margin-bottom: 10px;
	}

	.title {
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 22px;
	}
}
