@import '../../../../styles/variables.scss';

.titleStt {
	color: $gray_9;
	font-weight: 600;
}
.blockEnginesFlex {
	display: flex;
	align-items: center;
	grid-area: 2 / 1 / 3 / 2;
}

.blockEngines {
	margin-left: 16px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.engine {
	margin-bottom: 8px !important;
}
