@import './variables';

@mixin ellipsis {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
}

@mixin cancelBtn {
	background-color: $purple_2 !important;
	color: $purple_9 !important;
}

@mixin resetBtnDefaultStyles {
	background-color: transparent !important;
	border: none !important;
	outline: none !important;
	padding: 0 !important;
}

/* Миксин для генерации медиа запроса */
@mixin media($max-width) {
	@media screen and (max-width: $max-width) {
		@content;
	}
}

@mixin custom-vertical-scrollbar {
	/* Для Webkit браузеров (Chrome, Safari, Edge) */
	&::-webkit-scrollbar {
		display: none;
		width: 4px; /* Тонкая ширина скроллбара */
	}

	&::-webkit-scrollbar-track {
		background: transparent; /* Фон трека скроллбара */
	}

	&::-webkit-scrollbar-thumb {
		background-color: lightgray; /* Цвет ползунка */
		border-radius: 8px; /* Скругление углов ползунка */
		border: none; /* Без границы */
	}

	&::-webkit-scrollbar-button {
		display: none; /* Убираем кнопки со стрелками */
	}

	/* Для Firefox */
	scrollbar-width: thin; /* Тонкая ширина скроллбара */
	scrollbar-color: lightgray transparent; /* Цвет ползунка и фона трека */
}
