@import '../../../../../../../../../../styles/variables.scss';

.hiddenTags {
  margin-top: 24px;
}

.content {
  margin-bottom: 16px;
}

.title {
  color: $gray_7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.titleModal {
  color: $gray_9 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.contentModal {
  margin-top: 24px;
}