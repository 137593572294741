.root {
	display: flex;
	align-items: center;
	margin-bottom: 25px;

	.text {
		margin-right: 10px;
	}

	&.spaceBetween {
		justify-content: space-between;
	}
}
