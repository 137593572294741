@import 'styles/variables';

.textarea {
	width: 100%;
	// height: 320px;
	background: #f8fafc;
	border: 1px solid #cdd5df;
	border-radius: 2px;
	padding: 8px 12px;
	font-weight: 400;
	min-width: 100%;

	&__wrap {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 22px;
		color: #738094;
		position: relative;
	}

	&__head {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	&__title {
		font-size: 14px !important;
		color: $gray_7 !important;
		margin-bottom: 4px !important;
		display: flex;
	}
}

.textarea.hasPlaceholder::first-line {
	font-weight: 600;
}

.textarea:focus {
	outline: none;
}
