.root {
	display: flex;
	align-items: center;

	.text {
		color: #2f3747;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}
}
