@import 'src/styles/mixins.scss';

.root {
	display: flex;
	justify-content: space-between;
	padding: 16px 24px;
	margin: 0 -24px 10px -24px;
	border-right: 3px solid transparent;
	transition: 0.1s;

	&:hover {
		background-color: #f9f0ff;
		border-right: 3px solid #9254de;
	}

	.content {
		width: 70%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.title {
			margin-bottom: 5px;

			.defaultTitle {
				color: #000;
				font-weight: 600;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 260px;

				@include media(1440px) {
					width: 240px;
				}

				@include media(1280px) {
					width: 220px;
				}
			}
		}
	}

	.controls {
		width: 20%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;

		.buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-right: -5px;
		}

		span {
			margin-right: 4px;
		}
	}

	.switch {
		align-items: center;
	}
}

.selected {
	background-color: #f9f0ff;
	border-right: 3px solid #9254de;
}
