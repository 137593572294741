@import 'styles/variables';

.root {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 24px;

	.preloader {
		height: 500px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.section {
		display: flex;
		flex-direction: column;

		.title {
			color: #738094;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			margin-bottom: 10px;
		}

		.tagsContainer {
			display: flex;
			flex-direction: column;
			max-height: 180px; /* фиксированная высота */
			overflow-y: auto; /* внутренний скролл */

			&::-webkit-scrollbar {
				width: 4px; /* Ширина скроллбара */
			}

			&::-webkit-scrollbar-thumb {
				background-color: #e3e8ef; /* Цвет скроллбара */
				border-radius: 4px; /* Радиус скроллбара */
			}

			.checkbox {
				display: flex;
				align-items: center;
				margin-right: 8px !important;
			}
		}

		.separator {
			height: 1px;
			background: var(--Gray-gray-3, #e3e8ef);
			box-shadow: 0 -3px 7px 1px rgba(0, 0, 0, 0.1);
		}
	}

	.buttonsBlock {
		display: flex;
		justify-content: flex-end;
		margin: 16px 0;
	}
}
