@import 'styles/variables';

.sttTitle {
	font-weight: 600 !important;
	color: $gray_7;
	margin-top: 16px;
}

.sttAllFoudCalls {
	font-weight: 700 !important;
	color: $gray_7;
}
