.control {
	color: #738094;

	&:hover {
		color: #2f3747;
		.icon {
			fill: #2f3747;
		}
	}

	.icon {
		fill: #738094;

		&:hover {
			fill: #2f3747;
		}
	}
}

.focused {
	color: #2f3747;

	.icon {
		fill: #2f3747;
	}
}
