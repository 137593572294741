@import 'src/styles/mixins.scss';

.container {
	max-width: 1740px;
	margin: 0 auto;
	padding: 0 50px;
	padding-bottom: 25px;

	@include media('1280px') {
		padding: 0 25px;
	}
}
