.root {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;

	.title {
		color: #2f3747;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	}

	.infoLink {
		color: #722ed1;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px;
	}
}
