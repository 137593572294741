@import '../../../styles/variables';

$min_width: 44px;
$maxWidth: 168px;

.tagItem {
	margin-right: 4px;
	margin-bottom: 4px;
	& .loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 22px;
	}
}

.settings {
	position: absolute;
	top: -1px;
	right: -1px;
	height: 100%;
	width: auto;
	cursor: pointer;
	display: none;
}

.emptyTagWidth {
	max-width: $maxWidth;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tooltipMenu {
}

.loading {
	width: auto;
	height: 100%;
}

.tagGhost {
	position: relative;
	display: inline-block;
	padding: 1px 8px;
	border-radius: 5px;
	color: #738094;
	border: 1px dashed #cdd5df;
	cursor: pointer;
	font-size: 12px;
	line-height: 18px;
}

.tagGhostWithTitle {
	position: relative;
	display: inline-block;
	padding: 1px 8px;
	border-radius: 5px;
	color: $green_7;
	border: 1px dashed $green_4;
	cursor: pointer;
	background-color: $green_1;
	font-size: 12px;
	line-height: 18px;
}

.tagFull {
	position: relative;
	display: inline-block;
	padding: 1px 8px;
	border-radius: 5px;
	color: $green_9;
	border: 1px solid $green_4;
	cursor: pointer;
	font-size: 12px;
	line-height: 18px;
	background-color: $green_2;
	min-width: $min_width;
	&:hover {
		cursor: auto;
		background-color: $green_3;
		& .settings {
			display: flex;
		}
	}
}
