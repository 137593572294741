.loader {
	width: 100% !important;
	height: 100% !important;
	padding: 40px !important;
}

.custom_scroll_y {
	overflow-y: auto !important;
	&::-webkit-scrollbar {
		height: 8px;
		background-color: #fff;
		width: 8px;
		cursor: pointer;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #a3aebe;
		background: #a3aebe;
		border-radius: 14px;
		cursor: pointer;
	}
}
