.employeeBlock {
	width: 100%;
	overflow: hidden;
}

.employeePhone {
	display: flex;
	margin-bottom: 6px;
}

.employeeDuration {
	display: flex;
	align-items: center;
}
