.tagsBlock {
	display: flex;
	user-select: text;
	cursor: text;

	.tagsTitle {
		margin-right: 15px;
		margin-top: 5px;
		min-width: 100px;
		user-select: text;
		cursor: text;
	}
}
