.CheckList {
	display: flex;
	flex-direction: column;
	font-weight: 400;
	font-style: normal;
	font-size: 12px;
	font-family: 'Inter';
	color: #738094;
	align-items: flex-start;
}

.CheckListTitle {
	line-height: 20px;
	margin-bottom: 5px;
}

.CheckListBody {
	display: flex;
	flex-wrap: wrap;
	line-height: 22px;
	overflow: hidden;
}

.CheckListItem {
	min-width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 1px 6.5px;
	transition: opacity 0.2s ease;
	cursor: pointer;
	border: none;
	outline: none;
	box-shadow: none;
	background: #f8fafc;
	margin-bottom: 4px;
}

.CheckListItem:first-child {
	background: #eef2f6;
	border-radius: 5px 0 0 5px;
}

.CheckListItem:last-child {
	border-radius: 0 5px 5px 0;
}

.CheckListItem.active {
	background: #cdd5df;
	color: #2f3747;
}

.CheckListItem.isManual {
	background: #D9F7BE;
	color: #389E0D;
}

.CheckListItem.active svg * {
	fill: #2f3747;
}

.CheckListItem:hover {
	opacity: 0.7;
}
