.tagsFilter {
	.inputsBlock {
		margin-top: 15px;

		.inputWrapper {
			margin-bottom: 20px;
		}
	}

	.buttonsBlock {
		margin-top: 30px;
		text-align: right;

		.saveButton {
			text-transform: none;
		}

		.cancelButton {
			margin-right: 15px;
		}
	}
}
