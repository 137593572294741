@import 'src/styles/mixins.scss';

.root {
	display: flex;

	.inputClassNameWrapper {
		width: 89%;
		margin: 0 15px 25px 0;

		@include media(1675px) {
			width: 88%;
		}

		@include media(1550px) {
			width: 87%;
		}

		@include media(1444px) {
			width: 86%;
		}

		@include media(1353px) {
			width: 85%;
		}
	}
}
