@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

/*
* Colors
* ======================================================================== */

$purple_1: #f9f0ff;
$purple_2: #efdbff;
$purple_3: #d3adf7;
$purple_4: #b37feb;
$purple_5: #9254de;
$purple_6: #722ed1;
$purple_7: #531dab;
$purple_8: #391085;
$purple_9: #22075e;
$purple_10: #120338;

$blue_1: #f9f0ff;
$blue_2: #d6e4ff;
$blue_3: #d3adf7;
$blue_4: #b37feb;
$blue_5: #9254de;
$blue_6: #722ed1;
$blue_7: #531dab;
$blue_8: #391085;
$blue_9: #22075e;
$blue_10: #120338;

$gray_1: #ffffff;
$gray_2: #f8fafc;
$gray_3: #eef2f6;
$gray_4: #e3e8ef;
$gray_5: #cdd5df;
$gray_6: #a3aebe;
$gray_7: #738094;
$gray_8: #545f75;
$gray_9: #2f3747;
$gray_10: #1b202b;

$geekblue_1: #f0f5ff;
$geekblue_2: #d6e4ff;
$geekblue_3: #adc6ff;
$geekblue_4: #85a5ff;
$geekblue_5: #597ef7;
$geekblue_6: #2f54eb;
$geekblue_7: #1d39c4;
$geekblue_8: #10239e;
$geekblue_9: #061178;
$geekblue_10: #030852;

$green_1: #eeffdb;
$green_2: #d9f7be;
$green_3: #b7eb8f;
$green_4: #95de64;
$green_5: #73d13d;
$green_6: #52c41a;
$green_7: #389e0d;
$green_8: #237804;
$green_9: #135200;
$green_10: #092b00;

$red_1: #fff1f0;
$red_2: #ffccc7;
$red_3: #ffa39e;
$red_4: #ff7875;
$red_5: #ff4d4f;
$red_6: #f5222d;
$red_7: #cf1322;
$red_8: #a8071a;
$red_9: #820014;
$red_10: #5c0011;

$orange_1: #fff7e6;
$orange_2: #ffe7ba;
$orange_3: #ffd591;
$orange_4: #ffc069;
$orange_5: #fa8c16;
$orange_6: #fa8c16;
$orange_7: #d46b08;
$orange_8: #ad4e00;
$orange_9: #873800;
$orange_10: #612500;

$gold_4: #ffd666;
$gold_5: #ffc53d;

$cyan_2: #b5f5ec;
$cyan_3: #87e8de;
$cyan_5: #36cfc9;
$cyan_9: #00474f;

:export {
	purple_1: strip-unit($purple_1);
	purple_2: strip-unit($purple_2);
	purple_3: strip-unit($purple_3);
	purple_4: strip-unit($purple_4);
	purple_5: strip-unit($purple_5);
	purple_6: strip-unit($purple_6);
	purple_7: strip-unit($purple_7);
	purple_8: strip-unit($purple_8);
	purple_9: strip-unit($purple_9);
	purple_10: strip-unit($purple_10);

	blue_1: strip-unit($blue_1);
	blue_2: strip-unit($blue_2);
	blue_3: strip-unit($blue_3);
	blue_4: strip-unit($blue_4);
	blue_5: strip-unit($blue_5);
	blue_6: strip-unit($blue_6);
	blue_7: strip-unit($blue_7);
	blue_8: strip-unit($blue_8);
	blue_9: strip-unit($blue_9);
	blue_10: strip-unit($blue_10);

	gray_1: strip-unit($gray_1);
	gray_2: strip-unit($gray_2);
	gray_3: strip-unit($gray_3);
	gray_4: strip-unit($gray_4);
	gray_5: strip-unit($gray_5);
	gray_6: strip-unit($gray_6);
	gray_7: strip-unit($gray_7);
	gray_8: strip-unit($gray_8);
	gray_9: strip-unit($gray_9);
	gray_10: strip-unit($gray_10);

	geekblue_1: strip-unit($geekblue_1);
	geekblue_2: strip-unit($geekblue_2);
	geekblue_3: strip-unit($geekblue_3);
	geekblue_4: strip-unit($geekblue_4);
	geekblue_5: strip-unit($geekblue_5);
	geekblue_6: strip-unit($geekblue_6);
	geekblue_7: strip-unit($geekblue_7);
	geekblue_8: strip-unit($geekblue_8);
	geekblue_9: strip-unit($geekblue_9);
	geekblue_10: strip-unit($geekblue_10);

	green_1: strip-unit($green_1);
	green_2: strip-unit($green_2);
	green_3: strip-unit($green_3);
	green_4: strip-unit($green_4);
	green_5: strip-unit($green_5);
	green_6: strip-unit($green_6);
	green_7: strip-unit($green_7);
	green_8: strip-unit($green_8);
	green_9: strip-unit($green_9);
	green_10: strip-unit($green_10);

	red_1: strip-unit($red_1);
	red_2: strip-unit($red_2);
	red_3: strip-unit($red_3);
	red_4: strip-unit($red_4);
	red_5: strip-unit($red_5);
	red_6: strip-unit($red_6);
	red_7: strip-unit($red_7);
	red_8: strip-unit($red_8);
	red_9: strip-unit($red_9);
	red_10: strip-unit($red_10);

	orange_1: strip-unit($orange_1);
	orange_2: strip-unit($orange_2);
	orange_3: strip-unit($orange_3);
	orange_4: strip-unit($orange_4);
	orange_5: strip-unit($orange_5);
	orange_6: strip-unit($orange_6);
	orange_7: strip-unit($orange_7);
	orange_8: strip-unit($orange_8);
	orange_9: strip-unit($orange_9);
	orange_10: strip-unit($orange_10);

	gold_4: strip-unit($gold_4);
	gold_5: strip-unit($gold_5);

	cyan_2: strip-unit($cyan_2);
	cyan_3: strip-unit($cyan_3);
	cyan_5: strip-unit($cyan_5);
	cyan_9: strip-unit($cyan_9);
}
