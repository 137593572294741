@import '../../../../styles/variables';

.title {
	color: $gray_7;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	margin-bottom: 5px;
}

.titleFlex {
	display: flex;
	align-items: center;
	& .title {
		margin-right: 8px;
	}
}

.selectRequired {
	position: relative;
}

.requiredTitle {
	position: absolute;
	top: 94%;
	left: 0;
	color: $red_4;
}
