.sort-block-component {
	display: flex;
}

.select-type-sort {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.select-type-sort > svg {
	width: 20px;
}

.dictionary-popup {
	width: 250px;
	position: absolute;
	z-index: 1;
}

.popup-stepper-content {
	height: 550px;
}

.dictionary-textarea-title {
	font-size: 12px;
	font-weight: bold;
	color: rgba(0, 0, 0, 0.4);
}

.dictionary-textarea {
	width: 100%;
	min-height: 70px;
	outline: 0;
}

.phrases-textarea {
	height: 450px;
	/* max-height: 450px; */
	resize: none;
}

.dict-item {
	padding: 5px 10px;
	margin-bottom: 5px;
	border-radius: 4px;
	transition: all 0.2s ease-in-out;
	user-select: none;
	cursor: pointer;
}

.dict-item:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.dicts-block {
	width: 100%;
	height: 353px;
	overflow: auto;
}

.wait-dicts-loader {
	width: 100%;
	display: flex;
	justify-content: center;
}

.menu-stepper {
	width: 100%;
	height: 36px;
	padding: 3px 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: absolute;
	left: 0;
	bottom: 0;
}

.calls-empty {
	line-height: 150px !important;
	text-align: center;
}

.wrapperTitle {
	margin-top: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
