@import 'styles/mixins';

.footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;

    &__margin {
        margin-right: 8px !important;
    }

    &__cancel {
        @include cancelBtn;
    }
}
