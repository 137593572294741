.langHandler {
	& .MuiInput-input:focus {
		background-color: #fff;
		color: #a3aebe;
	}
	border: none;
	outline: none;
	&::before {
		border: none !important;
	}
	&::after {
		border: none !important;
	}
	margin: 0 10px;
}

.langHandlerItem {
	&.MuiMenuItem-root {
		background-color: #fff !important;
	}
}
