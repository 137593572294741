.manualGroup {
	display: flex;
	flex-direction: column;
}

.modalBtns {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px;
	min-height: 40px;
}
