.sttContent {
	width: 500px;
}

.usersInfoLine {
	margin-bottom: 20px;
	display: grid;
	grid-template-columns: 50% 50%;
	gap: 15px;
}
