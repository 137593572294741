.container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin: 5px 0;

	.tagContainer {
		display: flex;
		user-select: text;
		cursor: text !important;

		.tag {
			font-size: 12px;
			color: #061178;
			border-radius: 5px;
			border: 1px solid #85a5ff;
			background: #d6e4ff;
			padding: 1px 8px;
			margin: 0 4px 4px 0;
			cursor: text !important;
			user-select: text;
		}

		.tagCursorAuto {
			cursor: text !important;
			user-select: text;
		}
	}

	.skeletonContainer {
		width: 100%;

		.skeleton {
			width: 100%;
			max-width: 500px;
			margin-right: 5px;
		}

		.skeletonWithMarginTop {
			margin-top: 10px;
		}
	}
}
