.root {
	width: 100%;
	display: flex;
	padding: 15px;
	background: #fff1f0;

	.textBlock {
		display: flex;
		flex-direction: column;
		margin-left: 15px;
		font-family: Inter;
		font-style: normal;
		font-weight: 400;
		text-align: left;

		.firstLine {
			color: #2f3747;
			font-size: 16px;
			line-height: 24px;
		}

		.secondLine {
			color: #738094;
			font-size: 14px;
			line-height: 22px;
		}
	}
}
