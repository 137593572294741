@import 'styles/variables';
@import 'src/styles/mixins.scss';

.root {
	display: flex;
	justify-content: flex-start;
	position: relative;
	margin-bottom: 16px;
	background-color: $gray_2;
	padding: 8px 16px 8px 8px;
	border-radius: 10px;
}

.head {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: $gray_7;
	font-size: 12px;
	line-height: 20px;
	margin-bottom: 4px;
}

.author {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 14px;
	line-height: 22px;
	margin-right: 8px;

	& svg {
		margin-right: 4px;
	}
}

.time {
	width: max-content;
	font-size: 12px;
	line-height: 22px;
	margin-right: 8px;
}

.icon {
	margin-right: 8px;
	padding-top: 2px;
}

.title {
	width: calc(100% - 40px);
	color: $gray_9;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.message {
	color: $gray_9;
	font-size: 14px;
	line-height: 22px;
	white-space: pre-wrap;
}

.optionsSelect {
	position: absolute !important;
	top: 4px !important;
	right: 18px !important;
}

.optionsButton {
	width: 16px;
	height: 16px;
	background-color: transparent;
	padding: 0;
}

.editableTextarea {
	width: 100% !important;
	font-weight: 400 !important;
}

.editableInput {
	width: 100% !important;
}

.addTitleButton {
	background-color: transparent;
	padding: 0;

	&:hover {
		color: $purple_6;
	}
}

.content {
	flex-grow: 1;

	.loader {
	}

	.headerWrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		position: relative;

		.header {
			color: #738094;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			position: absolute;
			left: 49.8%;

			@include media(1735px) {
				left: 50%;
			}
			@include media(1700px) {
				left: 50.5%;
			}
			@include media(1535px) {
				left: 51%;
			}
			@include media(1535px) {
				left: 52%;
			}
			@include media(1380px) {
				left: 51.8%;
			}
			@include media(1300px) {
				left: 52.5%;
			}
		}

		.topTranslateButtonWrapper {
			width: auto;

			.topTranslateButton {
				padding: 0 8px !important;
				border: 1px solid #cdd5df !important;
				background: #f8fafc !important;
				margin-left: auto !important;
			}
		}
	}
}

.whithTranslateWrapper {
	display: flex;
	align-items: center;
	gap: 40px;

	.original {
		width: 50%;
		max-width: 690px;

		@include media(1720px) {
			width: 47.5%;
		}

		@include media(1600px) {
			width: 47.5%;
		}

		@include media(1440px) {
			width: 48.5%;
		}

		@include media(1397px) {
			width: 49.5%;
		}

		@include media(1315px) {
			width: 50%;
		}

		@include media(1300px) {
			width: 51%;
		}
	}

	.translated {
		width: 50%;
		max-width: 558px;
	}

	.errorMessage {
		display: flex;
		padding: 16px;
		background-color: #fff1f0;

		.errorText {
			margin-left: 16px;
		}
	}

	.emptyMessage {
		display: flex;
		padding: 16px;
		background-color: #f9f0ff;

		.emptyText {
			margin-left: 16px;
		}
	}

	.buttonsBlock {
		margin-top: 30px;
		display: flex;
		justify-content: flex-end;

		.cancelButton {
			border-radius: 5px;
			background: #efdbff !important;
			color: #22075e !important;
		}
	}
}
