@import '../../../styles/variables';

.manualGroupHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}

.manualGroupFlex {
	display: flex;
	align-items: center;
	& h4 {
		margin-right: 16px;
	}
}

.manualGroupTootlip {
	& div {
		display: flex;
	}
}

.manualGroupLabel {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: #738094;
}

.tagsContainer {
	display: inline-flex;
	flex-wrap: wrap;
}
