.selectForHeader {
}

.control {
	position: relative !important;
	align-items: start !important;
	border-radius: 30px !important;
	border: 1px #9254de solid !important;
	font-size: 12px !important;
	line-height: 22px !important;
	color: #2f3747 !important;
	height: 26px !important;
}

.valueContainer {
	position: absolute !important;
	top: -5px !important;
	width: 100% !important;
}
.indicator {
	position: absolute !important;
	top: -4px !important;
	right: 0 !important;
}

.simpleSelect {
	margin-bottom: 15px;
}
