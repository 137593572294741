.container {
	width: 100%;

	& .header {
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	& .body {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}
