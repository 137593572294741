.scoreWrappper {
  font-size: 14px;
  line-height: 22px;
  text-align: right;
}

.callScoreWrapper {
  font-size: 14px;
  line-height: 22px;
}

.score {
    color: #738094;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.callScore {
  color: #738094;
  margin-top: 4px;
  white-space: nowrap;
}
