@import '../../styles/variables';

.sttContent {
	width: 500px;
}

.sttSelect {
 &:first-child { 
	grid-column: 1 / 2;
	grid-row: 1;
 }
 &:nth-child(2) {
	grid-column: 1 / 2;
	grid-row: 2;
 }
 &:last-child {
	grid-column: 2 / 3;
	grid-row: 2;
 }
}

.sttSelects {
	display: grid;
	grid-template-columns: 50% 50%;
	gap: 15px;
}

.sttContentFull {
	width: 100%;
	& .sttSelect {
		margin-bottom: 20px;
	}
	& .sttSelects {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.sttSelect {
		&:first-child { 
		 grid-column: 1 / 2;
		 grid-row: 1;
		}
		&:nth-child(2) {
		 grid-column: 2 / 2;
		 grid-row: 1;
		}
		&:last-child {
		 grid-column: 3;
		 grid-row: 1;
		}
	 }
}

.controlButtonsBlock {
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
}

.sttCountPerIteration {
	width: 208px !important;
	margin-bottom: 24px !important;
}

.sttEconomize {
	color: $gray_9;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 25px !important;
}

.order {
	color: $gray_7;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	margin-bottom: 16px !important;
	margin-top: 16px !important;
}
