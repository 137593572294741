.circularWrapper {
	position: absolute;
	top: 35%;
	left: calc(50% - 50px);
}

.circular {
	width: 100px;
	height: 100px;
}
