.root {
	border-radius: 10px;
	background: #eef2f6;
	padding: 16px;
	margin-bottom: 25px;

	.title {
		color: #2f3747;
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
		margin-bottom: 15px;
	}
}
