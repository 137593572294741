.tasksWrapper {
	display: flex;
	margin-bottom: 10px;
}

.taskWidth {
	width: 100%;
}

.task {
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.taskItem {
	display: flex;
	align-items: center;
	height: 31px;
}

.taskTitle {
	margin: 0 5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

.taskProgress {
	margin: 5px !important;
	padding-left: 10px !important;
	border-left: 1px #738094 solid !important;
}

.realTimeTask {
	margin-top: 10px;
}
