.CallsCheckLists {
	margin-top: 16px;
	margin-bottom: 29px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	&:last-child {
		margin-bottom: 0;
	}
}

.chekListAppraised {
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: #738094;
}
