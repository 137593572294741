.scorePercent {
  display: inline-flex;
  align-items: center;
  padding: 0px 8px;
  border-radius: 5px;
  margin-top: 4px;
}

.circle {
  width: 8px;
  min-width: 8px;
  min-height: 8px;
  height: 8px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 6px;
}

.background--red {
  background: #FFCCC7;
  color: #F5222D;
}

.background--red {
  & .circle {
    background: #F5222D;
  }
}

.background--yellow {
  background: #FFE7BA;
  color: #FA8C16;
}

.background--yellow {
  & .circle {
    background: #FA8C16;
  }
} 

.background--green {
  background: #D9F7BE;
  color: #389E0D;
}

.background--green  {
  & .circle {
    background: #389E0D;
  }
}