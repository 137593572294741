@import "styles/variables";

.root {
	display: flex;
	align-items: center;

	.input {
		all: unset;
		font-weight: 700;
		color: $gray_7;
		font-family: Inter, sans-serif;
	}

	.blackColor {
		color: black !important;
	}

	.checkButton:disabled svg path {
		fill: $gray_5;
	}
}
