@import '../../../styles/variables.scss';

.root {
	display: flex;
}

.upload {
	width: 70%;
	& p {
		font-family: 'Inter', sans-serif;
	}
	& p + p {
		margin: 0;
	}

	& :global(.ant-upload-drag-icon svg path) {
		fill: $purple_5;
	}

	& :global(.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover) {
		border-color: $purple_5 !important;
	}

	:global(.ant-upload-list.ant-upload-list-text) {
		font-family: 'Inter', sans-serif;
	}

	:global(.ant-upload-drag) {
		height: auto !important;
	}

	:global(.ant-upload-icon) {
		display: flex;
	}
}

.requirements {
	width: calc(100% - 70% - 16px);
	margin-left: 16px;
	color: $gray_9;
}

.requirementsTitle {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	& p {
		margin-left: 4px;
	}
}

.requirementsItem {
	display: flex;
	font-size: 12px;
	line-height: 20px;
	margin-bottom: 4px;
	& p {
		font-weight: 600;
	}
}
