.universalInputWrapper {
	.label {
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: #738094;
		white-space: nowrap;

		.fieldRequired {
			color: red;
		}

		div {
			display: flex;
			align-items: center;
			margin-left: 5px;
		}
	}

	.inputWrapper {
		position: relative;

		.iconStart {
			position: absolute;
			top: 12px;
			left: 10px;
		}

		.universalInput {
			width: 100%;
			outline: none;
			padding: 6px 10px 8px 10px;
			border-radius: 5px;
			color: black;
			background: #f8fafc;
			margin-top: 5px;

			&::placeholder {
				opacity: 0.55;
			}

			&.outlined {
				text-transform: none;
				border-radius: 5px;
				border: 1px solid #e3e8ef;
			}
			&.filled {
				text-transform: none;
				border-radius: 5px;
				background-color: #f8fafc;
				color: black;

				&:hover {
					background-color: #f8fafc;
				}
			}
			&.plain {
				text-transform: none;
				border: none;

				&:hover {
					border: none;
				}
			}
			&.disabled {
				pointer-events: none;
			}
		}

		.iconEnd {
			position: absolute;
			top: 12px;
			right: 12px;
		}
	}
}
